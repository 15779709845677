import React from 'react';
import {connect} from 'react-redux';
import {Alert, Icon} from 'antd';
import Spinner from './uielements/spin';

const AlertStyles = {
  textAlign: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 200000
};

const Component = ({isConnected}) => {
  return (
    !isConnected ?
      <Alert style={AlertStyles} type='warning' message={
        <div>
          It seems you are disconnected from our service, trying to reconnect...&nbsp;&nbsp;
          <Spinner indicator={<Icon type='loading' />}/>
        </div>
      }/>
      :
      null
  )
};

export default connect(
  state => ({
    isConnected: state.App.api_connected
  })
)(Component);
