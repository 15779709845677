export const DELIVERY_TIMES = {
  "10-11am": "10am - 11am",
  "13-14pm": "1pm - 2pm",
  "16-17pm": "4pm - 5pm",
  "19-20pm": "7pm - 8pm",
};

export const WEEKLY_DELIVERY= [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const ORDER_STATUS = [
  "delivered",
  "packaging",
  "on-the-way",
  "canceled",
  "pending",
];

export const setUserToken = token => localStorage.setItem('token', token);
export const getUserToken = () => localStorage.getItem('token');
export const clearUserToken = () => localStorage.removeItem('token');

export const calcPrices = (price, discount, quantity) => ({
  price: Math.round(((price - (price * (discount || 0)) / 100) * quantity) * 10) / 10,
  discount: Math.round((price * quantity) * 10) / 10,
});


export const getTotalPrice = products => {
  let totalPrice = 0;
  if (products) {
    // eslint-disable-next-line
    products.map(p => {
      const { price } = calcPrices(p.price, p.discount, p.quantity);
      if (price && !isNaN(price)) {
        totalPrice += price;
      }
    });
  }
  return totalPrice;
};


export const FileDownload = (data, filename, mime) => {
  const blob = new Blob([data], {type: mime || 'application/octet-stream'});
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  }
  else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};
