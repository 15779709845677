import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../Dashboard')),
  },
  {
    path: 'categories',
    component: asyncComponent(() => import('../Category/Categories')),
  },
  {
    path: 'orders',
    component: asyncComponent(() => import('../Orders/OrdersList')),
  },
  {
    path: 'delivery-partners',
    component: asyncComponent(() => import('../DeliveryPartner/DeliveryPartners')),
  },
  {
    path: 'products',
    component: asyncComponent(() => import('../Product/Products')),
  },
  {
    path: 'products/create',
    component: asyncComponent(() => import('../Product/ProductCreate')),
  },
  {
    path: 'products/:product_id/edit',
    component: asyncComponent(() => import('../Product/ProductEdit')),
  },
  {
    path: 'products/:product_id',
    component: asyncComponent(() => import('../Product/Product')),
  },
  {
    path: 'b2b-clients',
    component: asyncComponent(() => import('../BusinessClients/Clients')),
  },
  {
    path: 'b2b-shop/new-order',
    component: asyncComponent(() => import('../BusinessClients/NewOrder')),
  },
  {
    path: 'b2b-shop/order/:order_id/edit',
    component: asyncComponent(() => import('../BusinessClients/EditOrder')),
  },
  {
    path: 'b2b-shop',
    component: asyncComponent(() => import('../BusinessClients/Orders')),
  },
];

class AppRouter extends Component {
  render() {
    let { url, style } = this.props;
    if (url[url.length - 1] === '/') {
      url = url.substr(0, url.length - 2);
    }
    return (
      <div style={style}>
        <Switch>
          {routes.map(singleRoute => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact !== false}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

export default AppRouter;
