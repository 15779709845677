const options = [
  {
    key: '',
    label: 'sidebar.dashboard',
    leftIcon: 'ion-ios-analytics',
  },
  {
    key: 'orders',
    label: 'sidebar.orders',
    leftIcon: 'ion-android-apps',
  },
  {
    key: 'categories',
    label: 'sidebar.categories',
    leftIcon: 'ion-ios-bookmarks',
  },
  {
    key: 'products',
    label: 'sidebar.products',
    leftIcon: 'ion-ios-albums',
  },
  {
    key: 'delivery-partners',
    label: 'sidebar.deliveryPartners',
    leftIcon: 'ion-android-car',
  },
  {
    key: 'b2b',
    label: 'sidebar.b2b',
    leftIcon: 'ion-outlet',
    children: [
      {
        key: 'b2b-clients',
        label: 'sidebar.b2b-clients',
      },
      {
        key: 'b2b-shop',
        label: 'sidebar.shop',
      },
    ],
  },
  {
    key: 'inventory',
    label: 'sidebar.inventory',
    leftIcon: 'ion-pricetags',
    children: [
      {
        key: 'inventory-requests',
        label: 'sidebar.inventory-requests',
      },
    ],
  },
];
export default options;
