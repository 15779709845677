import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import {GraphQLClient, LOGIN_MUTATION, WSClient} from '../../graphql';
import {clearUserToken, getUserToken, setUserToken} from "../../helpers";

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*(action) {
    const { username, password } = action;
    try {
      const query = yield GraphQLClient.mutate({
        mutation: LOGIN_MUTATION,
        variables: { username, password },
      });

      const {error, message, token} = query.data.adminLogin;
      if(error) {
        return yield put({
          type: actions.LOGIN_ERROR,
          payload: message
        });
      }

      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
      });
    } catch (e) {
      console.log('Login SAGA Error -> ', e);
      yield put({
        type: actions.LOGIN_ERROR,
        payload: 'Unhandled error received! Try again later...',
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield setUserToken(payload.token);
    yield WSClient.close(true, false);
    WSClient.connectionParams.token = payload.token;
    yield WSClient.tryReconnect();
    yield put(push('/'));
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearUserToken();
    yield put(push('/signin'));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getUserToken();
    if (!token) {
      yield put(push('/signin'));
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(logout)
  ]);
}
