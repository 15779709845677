import { SubscriptionClient } from 'subscriptions-transport-ws';
import { ApolloClient, InMemoryCache, ApolloLink, gql } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import AppActions  from './redux/app/actions';
import {store} from "./redux/store";

// Create WebSocket client
export const WSClient = new SubscriptionClient(`${process.env.REACT_APP_WS_HOST || 'ws://localhost:4000'}/api/ws`, {
  reconnect: true,
  connectionParams: {
    token: localStorage.getItem('token'),
  },
});

WSClient.onConnected(() => store.dispatch(AppActions.api_connected()));
WSClient.onReconnected(() => store.dispatch(AppActions.api_connected()));

WSClient.onDisconnected(() => {
  store.dispatch(AppActions.api_disconnected());
});

// handling graphql and network errors
const ErrorHandler = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      // store.dispatch(AppActions.api_error_response());
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  if (networkError) {
    // store.dispatch(AppActions.api_network_error());
    console.log(`[Network error]: ${networkError}`);
  }
});

export const GraphQLClient = new ApolloClient({
  link: ApolloLink.from([ErrorHandler, WSClient]),
  cache: new InMemoryCache(),
});

const mutationFields = `
error
message
`;


const productFields = `
  _id
  nameEn
  nameRu
  nameAm
  unit
  price
  discount
  maxOrder
  minOrder
  category {
    _id,
    nameEn
    nameRu
    nameAm
    slug
    isActive
    productCount
    created_at
    order
  }
  photo
  isActive
  created_at
  keywords
  featured
  descriptionAm
  descriptionEn
  descriptionRu
  quantity
`;

export const LOGIN_MUTATION = gql`
  mutation AdminLogin($username: String!, $password: String!) {
    adminLogin(username: $username, password: $password) {
      token
      ${mutationFields}
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  query ProductsQuery($category: String) {
    products(category: $category) {
      ${productFields}
    }
  }
`;

export const PRODUCT_QUERY = gql`
  query ProductQuery($product_id: String!) {
    product(product_id: $product_id) {
      ${productFields}
    }
  }
`;

export const PRODUCT_CREATE_MUTATION = gql`
  mutation ProductCreateMutation(
      $nameEn: String!,
      $nameRu: String!,
      $nameAm: String!,
      $unit: String!,
      $price: Float!,
      $discount: Float!,
      $maxOrder: Float!,
      $minOrder: Float!
      $category: String!,
      $photo: String!,
      $keywords: String,
      $isActive: Boolean!,
      $descriptionAm: String,
      $descriptionRu: String,
      $descriptionEn: String,
  ) {
    productCreate(
      nameEn: $nameEn,
      nameRu: $nameRu,
      nameAm: $nameAm,
      unit: $unit,
      price: $price,
      discount: $discount,
      maxOrder: $maxOrder,
      minOrder: $minOrder,
      category: $category,
      photo: $photo,
      isActive: $isActive,
      keywords: $keywords,
      descriptionAm: $descriptionAm,
      descriptionRu: $descriptionRu,
      descriptionEn: $descriptionEn,
    ) {

      id
      ${mutationFields}
    }
  }
`;

export const PRODUCT_UPDATE_MUTATION = gql`
  mutation ProductUpdateMutation(
      $product_id: String!,
      $photo_data: String,
      $nameEn: String!,
      $nameRu: String!,
      $nameAm: String!,
      $unit: String!,
      $price: Float!,
      $discount: Float!,
      $maxOrder: Float!,
      $minOrder: Float!
      $category: String!,
      $keywords: String,
      $photo: String!,
      $isActive: Boolean!,
      $featured: Boolean!,
      $descriptionAm: String,
      $descriptionRu: String,
      $descriptionEn: String,
  ) {
    productUpdate(
      product_id: $product_id,
      photo_data: $photo_data,
      nameEn: $nameEn,
      nameRu: $nameRu,
      nameAm: $nameAm,
      unit: $unit,
      price: $price,
      discount: $discount,
      maxOrder: $maxOrder,
      minOrder: $minOrder,
      category: $category,
      photo: $photo,
      keywords: $keywords,
      isActive: $isActive,
      featured: $featured,
      descriptionAm: $descriptionAm,
      descriptionRu: $descriptionRu,
      descriptionEn: $descriptionEn,
    ) {
      ${mutationFields}
    }
  }
`;

export const PRODUCT_DELETE_MUTATION = gql`
  mutation ProductDelete($product_id: String!) {
    productDelete(product_id: $product_id) {
      ${mutationFields}
    }
  }
`;


export const PRODUCT_PHOTO_QUERY = gql`
  query ProductPhoto($product_id: String!) {
    productPhoto(product_id: $product_id) {
      photo
      _id
    }
  }
`;

export const CATEGORIES_QUERY = gql`
  query CategoriesQuery {
    categories {
      _id,
      nameEn
      nameRu
      nameAm
      slug
      isActive
      productCount
      created_at
      order
    }
  }
`;

export const CATEGORY_QUERY = gql`
  query CategoryQuery ($category_id: String!) {
    category (category_id: $category_id) {
      _id,
      nameEn
      nameRu
      nameAm
      slug
      isActive
      productCount
      created_at
      order
    }
  }
`;

export const CATEGORY_CREATE_MUTATION = gql`
  mutation CategoryCreate(
    $nameEn: String!,
    $nameRu: String!,
    $nameAm: String!,
    $slug: String!,
    $order: Int!,
    $isActive: Boolean!
  ) {
    categoryCreate(
      nameEn: $nameEn,
      nameRu: $nameRu,
      nameAm: $nameAm,
      slug: $slug,
      isActive: $isActive,
      order: $order
    ) {

      id
      ${mutationFields}
    }
  }
`;

export const CATEGORY_UPDATE_MUTATION = gql`
  mutation CategoryUpdate(
    $category_id: String!,
    $nameEn: String!,
    $nameRu: String!,
    $nameAm: String!,
    $slug: String!,
    $order: Int!,
    $isActive: Boolean!
  ) {
    categoryUpdate(
      category_id: $category_id,
      nameEn: $nameEn,
      nameRu: $nameRu,
      nameAm: $nameAm,
      slug: $slug,
      isActive: $isActive,
      order: $order
    ) {
      ${mutationFields}
    }
  }
`;

export const CATEGORY_DELETE_MUTATION = gql`
  mutation CategoryDelete(
    $category_id: String!
  ) {
    categoryDelete(
      category_id: $category_id,
    ) {
      ${mutationFields}
    }
  }
`;

export const COUPONS_QUERY = gql`
  query CouponsQuery {
    coupons {
      _id
      name
      code
      discount
      idActive
      created_at
      updated_at
    }
  }
`;

export const COUPON_QUERY = gql`
  query CouponQuery ($code: String!) {
    coupon(code: $code) {
      _id
      name
      code
      discount
      idActive
      created_at
      updated_at
    }
  }
`;

export const COUPON_CREATE_MUTATION = gql`
  mutation CouponCreate($name: String!, $code: String!, $discount: Float!, $isActive: Boolean!) {
    couponCreate(name: $name, code: $code, discount: $discount, isActive: $isActive) {
      id
      ${mutationFields}
    }
  }
`;

export const COUPON_UPDATE_MUTATION = gql`
  mutation CouponUpdate($coupon_id: String!, $name: String!, $code: String!, $discount: Float!, $isActive: Boolean!) {
    couponUpdate(coupon_id: $coupon_id, name: $name, code: $code, discount: $discount, isActive: $isActive) {
      ${mutationFields}
    }
  }
`;

export const COUPON_DELETE_MUTATION = gql`
  mutation CouponDelete($coupon_id: String!) {
    couponDelete(coupon_id: $coupon_id) {
      ${mutationFields}
    }
  }
`;

const orderFields = `
  _id
  phoneNumber
  address
  price
  deliveryTime
  notes
  status
  created_at
  businessClient
  products {
    ${productFields}
  }
`;

export const SHOP_ORDERS_QUERY = gql`
  query ShopOrders($from_date: String, $to_date: String, $onlyBusinessClientOrders: Boolean) {
    shopOrders(from_date: $from_date, to_date: $to_date, onlyBusinessClientOrders: $onlyBusinessClientOrders) {
      ${orderFields}
    }
  }
`;

export const SHOP_ORDER_QUERY = gql`
  query OrderQuery($order_id: String!) {
    shopOrder(order_id: $order_id) {
      ${orderFields}
    }
  }
`;

export const SHOP_ORDER_UPDATE_MUTATION = gql`
  mutation ShopOrderUpdateMutation(
      $orderId: String!,
      $businessClient: String!,
      $products: String!,
      $deliveryTime: String!,
      $notes: String!,
  ) {
    shopOrderUpdate(
      orderId: $orderId,
      businessClient: $businessClient,
      products: $products,
      deliveryTime: $deliveryTime,
      notes: $notes,
    ) {
      ${mutationFields}
    }
  }
`;

export const ORDER_UPDATE_MUTATION = gql`
  mutation ShopOrderUpdateMutation(
      $orderId: String!,
      $phoneNumber: String!,
      $address: String!,
      $deliveryTime: String!,
      $status: String!,
      $products: String!,
      $notes: String!,
  ) {
    shopOrderUpdate(
      orderId: $orderId,
      phoneNumber: $phoneNumber,
      address: $address,
      deliveryTime: $deliveryTime,
      status: $status,
      products: $products,
      notes: $notes,
    ) {
      ${mutationFields}
    }
  }
`;

export const SHOP_ORDER_CHART_DATA = gql`
  query ShopOrderChartData($fromDate: String, $toDate: String) {
    shopOrderChartData(fromDate: $fromDate, toDate: $toDate) {
      ordersData {
        date
        orderCount
        deliveredCount
        canceledCount
        totalAmount
        totalCanceledAmount
        totalDeliveredAmount
      }
      usersData {
        date
        userCount
        customerCount
      }
      avgCheckSizeData {
        date
        checkSize
        deliveredCheckSize
      }
    }
  }
`;

export const CHANGE_LOG_QUERY = gql`
  query ChangeLogQuery($entity: String!, $targetId: String!) {
    changeLogEntity(entity: $entity, targetId: $targetId) {
      _id
      admin {
        _id
        first_name
        last_name
        username
      }
      targetModel
      targetId
      actionType
      description
      current
      next
      created_at
    }
  }
`;

const businessClientFields = `
  _id
  name
  phoneNumber
  weeklyDelivery
  address
  discount
  isActive
  created_at
  updated_at
`;

export const BUSINESS_CLIENTS_QUERY = gql`
  query BusinessClients {
    businessClients {
      ${businessClientFields}
    }
  }
`;

export const BUSINESS_CLIENT_QUERY = gql`
  query BusinessClient($clientId: String!) {
    businessClient(clientId: $clientId) {
      ${businessClientFields}
      orders
    }
  }
`;

const businessClientMutationArgs = `
  $name: String!, $phoneNumber: String!, $address: String!, $weeklyDelivery: String, $discount: Float!, $isActive: Boolean!
`;

const businessClientMutationParams = `
  name: $name, phoneNumber: $phoneNumber, address: $address, weeklyDelivery: $weeklyDelivery, discount: $discount, isActive: $isActive
`;

export const BUSINESS_CLIENT_CREATE_MUTATION = gql`
  mutation BusinessClientCreate(${businessClientMutationArgs}) {
    businessClientCreate(${businessClientMutationParams}) {
      ${mutationFields}
      id
    }
  }
`;

export const BUSINESS_CLIENT_UPDATE_MUTATION = gql`
  mutation BusinessClientUpdate(${businessClientMutationArgs}, $clientId: String) {
    businessClientUpdate(${businessClientMutationParams}, clientId: $clientId) {
      ${mutationFields}
    }
  }
`;

export const BUSINESS_CLIENT_DELETE_MUTATION = gql`
  mutation BusinessClientDelete($clientId: String!) {
    businessClientDelete(clientId: $clientId) {
      ${mutationFields}
    }
  }
`;

export const SET_BUSINESS_CLIENT_ORDER_MUTATION = gql`
  mutation SetBusinessClientOrder($clientId: String!, $deliveryTime: String!, $products: String!, $notes: String!) {
    setBusinessClientOrder(clientId: $clientId, deliveryTime: $deliveryTime, products: $products, notes: $notes) {
      ${mutationFields}
    }
  }
`;

export const DELIVERY_PARTNERS_QUERY = gql`
  query DeliveryPartnersQuery {
    deliveryPartners {
      _id
      first_name
      last_name
      phoneNumber
      created_at
      created_by
      last_login
    }
  }
`;

export const DELIVERY_PARTNERS_CREATE_MUTATION = gql`
  mutation DeliveryPartnerCreateMutation ($first_name: String!, $last_name: String!, $phoneNumber: String!, $password: String!) {
    deliveryPartnerCreate (first_name: $first_name, last_name: $last_name, phoneNumber: $phoneNumber, password: $password) {
      error
      message
    }
  }
`;

export const DELIVERY_PARTNERS_UPDATE_MUTATION = gql`
  mutation DeliveryPartnerUpdateMutation ($_id: String!, $first_name: String, $last_name: String, $phoneNumber: String, $password: String) {
    deliveryPartnerUpdate (_id: $_id, first_name: $first_name, last_name: $last_name, phoneNumber: $phoneNumber, password: $password) {
      error
      message
    }
  }
`;

export const DELIVERY_PARTNERS_DELETE_MUTATION = gql`
  mutation DeliveryPartnerDeleteMutation ($deliveryPartnerId: String!) {
    deliveryPartnerDelete (deliveryPartnerId: $deliveryPartnerId) {
      error
      message
    }
  }
`;
