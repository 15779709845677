import actions from "./actions";
import {clearUserToken, getUserToken} from "../../helpers";

const initState = {
  idToken: getUserToken(),
  authError: false,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        authError: action.payload,
      };
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        authError: false,
      };
    case actions.LOGOUT:
      clearUserToken();
      return {
        idToken: null,
        authError: false,
      };
    default:
      return state;
  }
}
